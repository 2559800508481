<template>
   <div class="col">
       <h1>Dev Page</h1>
        <p class="lead">
           <b>API Tests</b>
           current mode: <code>{{ mode }}</code>
        </p>

        <div class="row">
            <div class="col-md-4">
                <h3>Keycloak</h3>
                <b>URL: </b>{{ kc_url }}<br>
                <b>Realm: </b>{{ kc_realm }}<br>
                <b>ClientID: </b>{{ kc_client_id }}<br>
                <b>ClientSecret: </b>{{ kc_client_secret }}<br>
                <div class="btn-group" >

                <button
                    @click="this.$store.dispatch('key/login')" 
                    class="btn btn-success btn-sm">Login</button>
                <button 
                    @click="this.$store.dispatch('key/logout')"
                    class="btn btn-danger btn-sm">Logout</button>
                </div>
            </div>
            <div class="col-md-4">
                <h3>Actablue API</h3>
                <b>URL: </b>{{ ab_api }}
            </div>
            <div class="col-md-4">
                <h3>NarrowCasting API</h3>
                <b>URL: </b>{{ ncapi_url }}
            </div>
        </div>

        <div class="row py-3">
            <h3>User</h3>
            {{ username }}
        </div>
    <div class="row">
        <div class="col-md-2">
            <h1>Narrowcasting API Test</h1>
            <button @click="loadDevices()" class="btn btn-primary">Devices</button>
            <div class="col-md-12" v-for="dev in this.$store.getters['ncapi/device/read']('devices')" :key="dev">
                <button @click="loadDevice(dev.pk)">{{ dev.name }}</button>
            </div>
        </div>
    </div>
    Dev Test API with headers
   </div>
</template>
<script setup>
import {useStore} from 'vuex'
import axios from 'axios'

const store = useStore(); 

var mode = process.env.VUE_APP_MODE
var ncapi_url = process.env.VUE_APP_NCAPI
var ab_api = process.env.VUE_APP_AB_API
var kc_url = process.env.VUE_APP_KC_URL
var kc_realm = process.env.VUE_APP_KC_REALM
var kc_client_id = process.env.VUE_APP_KC_CLIENT_ID
var kc_client_secret = process.env.VUE_APP_KC_CLIENT_SECRET

var username = store.state.user.name


function testKC(){
    store.dispatch('key/login')
}

async function loadDevices(){
    store.dispatch('getAll', {type: 'device'})
    console.log(store.getters.getDevices);
    return store.getters.getDevices
    await store.dispatch('ncapi/device/readAll')
    console.log("Start Request ", store.getters['ncapi/device/read']('devices') )
    return store.getters['ncapi/device/read']('devices')
    /**
    await axios.get('http://localhost:8100/api/v1/devices/device/', {
        headers: {
            Authorization: store.getters.token,
            Administration: localStorage.administrationID
        }
    })
    .then(r=>{
        console.log("Got This response.", r)
    })
    .catch(e=>{
        console.log( "Got this error", e)
    })

    console.log("End Request ")

    */
}

async function loadDevice(id) {
    console.log(id)
    await store.dispatch('ncapi/device/read', id)
    console.log("Single Device: ", store.getters['ncapi/device/read']('device'))
}

//testKC()
</script>